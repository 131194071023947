<template>
	<div></div>
	<el-tabs
		class="sticky-tabs"
		type="card"
		v-model="activeName"
		:class="{ tabMargin: tabMargin }"
	>
		<el-tab-pane name="note">
			<template v-slot:label>編輯資料</template>
			<Note />
		</el-tab-pane>
		<el-tab-pane name="note1" label="prompt編輯頁">prompt編輯頁</el-tab-pane>
		<el-tab-pane name="note2" label="回覆Line bot">回覆Line bot</el-tab-pane>
		<el-tab-pane name="note3" label="QA對話查詢">QA對話查詢</el-tab-pane>
	</el-tabs>
</template>

<script>
import Note from "../ChargeMeContactBook/Note/Note.vue";
export default {
	name: "Home",
	data() {
		return {
			activeName: "note",
		};
	},
	components: {
		Note,
	},
	watch: {
		activeName(newVal) {
			// 這裡可以根據需要添加其他的邏輯
			console.log("Active tab changed to:", newVal);
		},
	},
	computed: {
		tabMargin() {
			return this.activeName === "note" && !this.$store.getters.mobileSize;
		},
	},
	mounted() {},
	methods: {},
};
</script>

<style lang="scss" scoped>
.tabMargin {
	margin-left: 250px;
}
</style>
